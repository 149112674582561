import { CHARACTERISTIC_KEY, PRODUCT_CATEGORY, PRODUCT_GROUP, PRODUCT_TYPE } from '../../product/lookup';
import { IProduct, IProductData } from "../../product/types";
import { INSTALLATION_POSITION, SYSTEM } from '../../project/lookup';

import { BasicInputsFormModel, VentilationComponentsFormModel } from "../models/FormModels";
import {
    filterProductsByCategories,
    filterProductsByCharacteristicsAll,
    filterProductsByCharacteristicsAny,
    filterProductsByTypes,
    filterProductsByVentilationArea,
    getRecommendedProductId,
} from "../CalculationService";

import { getAIOBasicSetVent } from "./AIOBasicSet";
import { getSystem } from "./System";


export function getAvailableVentilationComponentsForAIOBasicSet(projectType: string, totalLiftArea: number, installationPosition: string, productData: IProductData): IAvailableVentilationComponents {
    const vent = getAIOBasicSetVent(projectType, totalLiftArea);
    let weatherProtectionComponents = [];

    if (vent) {
        const aioBasicSetVentilationArea = vent.ventilationArea;
        const potentialWPComponents = productData.products.filter(p => p.productTypeId === PRODUCT_TYPE.WEATHERPROTECTION && p.ventilationArea === aioBasicSetVentilationArea);

        if (installationPosition === INSTALLATION_POSITION.VERTICAL) {
            weatherProtectionComponents = potentialWPComponents.filter(product => product.productGroupId === PRODUCT_GROUP.ALAS);
        } else {
            weatherProtectionComponents = potentialWPComponents.filter(product => product.productGroupId !== PRODUCT_GROUP.ALAS);
        }
    }

    return {
        ventComponents: [],
        weatherProtectionComponents,
    };
}

export type ProductSelector = IProduct & { isRecommended?: boolean };

interface IAvailableVentilationComponents {
    ventComponents: ProductSelector[];
    weatherProtectionComponents: ProductSelector[];
}

function getAvailableVentilationComponentsForManualConfig(
    minArea: number,
    basicInputFormValues: Pick<BasicInputsFormModel, 'projectType' | 'installationPosition' | 'abzRequired' | 'lowEnergyStandard' | 'quiet' | 'weathershelter'>,
    system: SYSTEM,
    productData: IProductData): IAvailableVentilationComponents {

    const { projectType, installationPosition, abzRequired, lowEnergyStandard, quiet, weathershelter } = basicInputFormValues;

    // Filter by installation position 'H'==horizontal or 'V'==vertikal
    const installationPositionKey = installationPosition === INSTALLATION_POSITION.HORIZONTAL
        ? CHARACTERISTIC_KEY.HORIZONTAL_INSTALLATION
        : CHARACTERISTIC_KEY.VERTICAL_INSTALLATION;

    // Filter by project type "MODERNIZED" or "NEWBUILDING"
    const projectTypeKey = (projectType + '_INSTALLATION') as CHARACTERISTIC_KEY;

    /// ---

    const ventFilterCharacteristics: CHARACTERISTIC_KEY[] = [installationPositionKey, projectTypeKey];

    // "System mit abZ ist verlangt" selected
    if (abzRequired) {
        ventFilterCharacteristics.push(CHARACTERISTIC_KEY.abZ);
    }

    // "Niedrigenergiestandard" selected
    if (lowEnergyStandard) {
        ventFilterCharacteristics.push(CHARACTERISTIC_KEY.LOWENERGY);
    }

    // "Geräuscharmer Antrieb" selected
    if (quiet) {
        ventFilterCharacteristics.push(CHARACTERISTIC_KEY.QUIET);
    }

    // AIO Basic system does not support battery packs; motors need a builtin self-open spring
    if (system === SYSTEM.AIO_BASIC) {
        ventFilterCharacteristics.push(CHARACTERISTIC_KEY.WITH_SPRING_RETURN);
    }

    let ventComponents = productData.products
        .filter(filterProductsByTypes([PRODUCT_TYPE.WEATHERPROTECTION], true))
        .filter(filterProductsByCategories([PRODUCT_CATEGORY.VENTCOMP], productData.productGroups))
        .filter(filterProductsByVentilationArea(minArea, null, true))
        .filter(filterProductsByCharacteristicsAll(ventFilterCharacteristics, productData.characteristics));

    if (weathershelter) {
        ventComponents = ventComponents.filter(filterProductsByCharacteristicsAny(
            [CHARACTERISTIC_KEY.WEATHERPROTECTION_COMPATIBLE, CHARACTERISTIC_KEY.WEATHERPROTECTION_BUILTIN],
            productData.characteristics
        ));
    }

    const recommendedProductId = getRecommendedProductId(ventComponents, productData.productGroups);
    ventComponents.forEach(c => {
        c.isRecommended = recommendedProductId && c.id === recommendedProductId;
    });

    /// ---
    // Wetterschutzkomponenten nur hinzufügen, wenn 'Wetterschutz' aktiv und kompatible Komponenten vorhanden

    const hasCompatibleComponents = () => ventComponents
    .filter(filterProductsByCharacteristicsAll([CHARACTERISTIC_KEY.WEATHERPROTECTION_COMPATIBLE], productData.characteristics))
    .length > 0

    if (!weathershelter || !hasCompatibleComponents()) {
        return { ventComponents, weatherProtectionComponents: [] };
    }

    const weatherProtectionComponents: ProductSelector[] = productData.products
        .filter(filterProductsByTypes([PRODUCT_TYPE.WEATHERPROTECTION]))
        .filter(filterProductsByVentilationArea(minArea, null, true))
        .filter(filterProductsByCharacteristicsAll([installationPositionKey, projectTypeKey], productData.characteristics));

    return { ventComponents, weatherProtectionComponents };
}

export function getAvailableVentilationComponents(
    totalLiftArea: number,
    minArea: number,
    basicValues:
        Parameters<typeof getSystem>[0] &
        Parameters<typeof getAvailableVentilationComponentsForManualConfig>[1] &
        Pick<BasicInputsFormModel, 'aioBasicSet' | 'projectType' | 'installationPosition'>,
    configValues: Parameters<typeof getSystem>[1],
    productData: IProductData
): IAvailableVentilationComponents {

    if (basicValues.aioBasicSet) {
        const { projectType, installationPosition } = basicValues;
        return getAvailableVentilationComponentsForAIOBasicSet(projectType, totalLiftArea, installationPosition, productData);
    } // else {

    const system = getSystem(basicValues, configValues);

    return getAvailableVentilationComponentsForManualConfig(minArea, basicValues, system, productData);
}

// -------

export const COMPONENT_GROUP_IDS_WITH_EINBAUWINKEL = [PRODUCT_GROUP.JK190DB, PRODUCT_GROUP.JK190];

export function getQuantitiesFromVentilationComponentsFormModel(ventilationComponentsValues: VentilationComponentsFormModel): [string, number][] {
    if (!ventilationComponentsValues) {
        return [];
    }

    const PRODUCT_QUANTITY_PREFIX = "quantity-";
    return Object.keys(ventilationComponentsValues)
        .filter(x => x.startsWith(PRODUCT_QUANTITY_PREFIX))
        .map<[string, number]>(key => [key.substring(PRODUCT_QUANTITY_PREFIX.length), Number(ventilationComponentsValues[key])]);
}
