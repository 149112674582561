import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import { Field, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { Form, Message } from 'semantic-ui-react';

import { ReduxFormKeys } from '../../../form/ReduxFormKeys';
import { IApplicationState } from '../../../../shared/ApplicationState';
import { TabSection } from '../../../../shared/components/TabSection';
import { renderHidden } from '../../../../shared/components/FormControls';
import { FormErrorList, syncedErrorsToList } from "../../../../shared/components/error-list";
import { nl2br } from '../../../../localization/LocalizationService';
import { useLocalization } from '../../../../localization/hook';

import { IProduct, IProductData } from '../../../product/types';
import { CHARACTERISTIC_KEY, PRODUCT_GROUP, PRODUCT_TYPE } from '../../../product/lookup';
import { INSTALLATION_POSITION, PROJECT_TYPE } from '../../../project/lookup';

import { projectApi } from '../../ProjectApi.redux';
import { filterProductsByCharacteristicsAll, filterProductsByTypes, getProductById } from "../../CalculationService";
import { isAllFormReady } from "../../calculation.selector";
import { ISelectedProduct } from "../../CalculationModels";
import { VentilationComponentsFormModel } from '../../models/FormModels';

import { BASIC_INPUTS_FORM_VALUES_SELECTOR } from "../02-ConfigurationInputs/ConfigurationInputsForm";

import { ProductsTable } from './_ProductsTable';


const VentilationComponentsForm: React.FC<Props> = props => {
    const {
        isAllFormReady,
        syncedErrors,
        basicInputFormValues,
        productDataList,
        filteredVentilationComponents,
        filteredWeatherProtectionComponents,
        selectedAIOBasicSet,
    } = props;

    const { localize } = useLocalization();

    if (!isAllFormReady) return <></>;

    if (basicInputFormValues.nrwgDelivered) {
        return <TabSection><p>{localize('info.weathershelter_preinstalled')}</p></TabSection>;
    }

    const errorList = syncedErrorsToList(syncedErrors).map(({ key, message }) => ({ key: 'field.' + key, message }));

    const showModernizedOpeningInfo =
        basicInputFormValues.projectType === PROJECT_TYPE.MODERNIZED &&
        !selectedAIOBasicSet;

    const showModernizedWeathershelterInfo =
        basicInputFormValues.projectType === PROJECT_TYPE.MODERNIZED &&
        basicInputFormValues.installationPosition === INSTALLATION_POSITION.VERTICAL &&
        basicInputFormValues.weathershelter;

    return (
        <>
            {!selectedAIOBasicSet && <Form>
                <Field name="validationTrigger" component={renderHidden}/>
            </Form>}

            {showModernizedOpeningInfo && (
                <Message color="blue" icon="info circle" content={
                    <p className="info-centered">
                        {nl2br(localize("info.modernized_check_opening"))}
                    </p>
                }/>
            )}

            <ProductsTable compact
                selector={filteredVentilationComponents}
                productData={productDataList}
                showBracketOptionOnGroups/>

            {basicInputFormValues.weathershelter && <>
                <h4 style={{marginTop: '20px', marginBottom: '20px', marginLeft: '30px'}}>{nl2br(localize('info.weathershelter_required_selected'))}</h4>
                {showModernizedWeathershelterInfo && <>
                    <Message color="blue" icon="info circle" content={
                        <p className="info-centered">
                            {nl2br(localize("info.modernized_weathershelter"))}
                        </p>
                    }/>
                    <Message color="blue" icon="info circle" content={
                        <p className="info-centered">
                            {nl2br(localize("info.modernized_weathershelter_custom"))}
                        </p>
                    }/>
                </>}
                <ProductsTable compact
                    selector={filteredWeatherProtectionComponents}
                    productData={productDataList}/>
            </>}

            <FormErrorList errorList={errorList}/>
        </>
    );
};

function validate(_values: VentilationComponentsFormModel, props: Props) {
    const errors: { selectedProducts?: string } = {};

    if (!props.basicInputFormValues) return errors;

    const nrwgProductList = props.selectedProducts
        .filter(p => p.quantity && p.productTypeId === PRODUCT_TYPE.NRWG && p.productGroupId !== PRODUCT_GROUP.BRACKET);

    if (!props.basicInputFormValues.nrwgDelivered && !props.basicInputFormValues.aioBasicSet) {
        if (nrwgProductList.length === 0) {
            errors.selectedProducts = 'ventComponents.error.noVentComponentSelected';
        }
    }

    if (!props.basicInputFormValues.weathershelter) return errors;

    /// Matching Weathershelter to Ventilation Components

    const nrwgNoWpComponents = flatArrayFromQuantities(props.basicInputFormValues.aioBasicSet
        ? [{ id: null, quantity: 1 } as unknown as ISelectedProduct] // We dont need a list of concrete products here for ct sets,
                                                                     // because only fitting weather protection components are shown for them
        : nrwgProductList.filter(filterProductsByCharacteristicsAll(
            [CHARACTERISTIC_KEY.WEATHERPROTECTION_BUILTIN],
            props.productDataList.characteristics,
            true
        ))
    )
        .map(sp => getProductById(props.productDataList.products, sp.id))
        .sort(sortProductsByArea);

    const wpComponents = flatArrayFromQuantities(props.selectedProducts
            .filter(filterProductsByTypes([PRODUCT_TYPE.WEATHERPROTECTION]))
            .filter(p => p.quantity > 0)
        )
        .map(sp => getProductById(props.productDataList.products, sp.id))
        .sort(sortProductsByArea);

    // The user should select one weather protection component for each ventilation component
    if (nrwgNoWpComponents.length !== wpComponents.length) {
        errors.selectedProducts = 'ventComponents.error.incompatibleCombination';
        return errors;
    }

    return wpComponents.map((wp, idx) => {
        const nrwg = nrwgNoWpComponents[idx];
        return validateWpForNrwg(nrwg, wp, props.productDataList);
    }).reduce((all, errors) => ({ ...all, ...errors }), { ...errors });
}

function validateWpForNrwg(nrwg: IProduct|null, wp: IProduct|null, productData: IProductData) {
    const errors: { selectedProducts?: string } = {};

    if (!nrwg) {
        console.debug(`Encountered missing product with id ${nrwg}`);
        return {};
    }

    if (!wp) {
        console.debug(`Encountered missing product with id ${wp}`);
        return {};
    }

    if (filterProductsByCharacteristicsAll([CHARACTERISTIC_KEY.SURFACE_MOUNTING], productData.characteristics)(nrwg)) {
        // BLU-370: Don't validate weathershelter size for SURFACE_MOUNTING
        return {};
    }

    if (wp.width === null || wp.height === null || nrwg.width === null || nrwg.height === null) {
        // Don't validate custom-sized products
        return {};
    }

    if (wp.width < nrwg.width || wp.height < nrwg.height)
    {
        errors.selectedProducts = 'ventComponents.error.weathershelterTooSmall';
    }

    return errors;
}

function flatArrayFromQuantities<T extends { quantity: number }>(arr: T[]): Omit<T, 'quantity'>[] {
    const result: Omit<T, 'quantity'>[] = [];
    arr.forEach(x => {
        for (let i = 0; i < x.quantity; i++) {
            result.push({ ...x, quantity: undefined });
        }
    });
    return result;
}

function sortProductsByArea<T extends Pick<IProduct, 'width'|'height'>>(a: T, b: T) {
    if (a === null) return b === null ? 0 : -1;
    if (b === null) return +1;

    const aArea = a.width * a.height;
    const bArea = b.width * b.height;
    if (aArea === bArea) {
        return a.width - b.width;
    }

    const aAreaNull = a.width === null || a.height === null;
    const bAreaNull = b.width === null || b.height === null;
    if (aAreaNull) return bAreaNull ? 0 : +1;
    if (bAreaNull) return -1;

    return aArea - bArea;
}

const mapStateToProps = createStructuredSelector({
    isAllFormReady: isAllFormReady,
    syncedErrors: getFormSyncErrors(ReduxFormKeys.ventilationComponentsForm),

    basicInputFormValues: getFormValues(ReduxFormKeys.basicInputsForm) as BASIC_INPUTS_FORM_VALUES_SELECTOR,

    selectedProducts: (state: IApplicationState) => state.calculationState.selectedProducts,
    totalLiftArea: (state: IApplicationState) => state.calculationState.totalLiftArea,
    filteredVentilationComponents: (state: IApplicationState) => state.calculationState.filteredVentilationComponents,
    filteredWeatherProtectionComponents: (state: IApplicationState) => state.calculationState.filteredWeatherProtectionComponents,
    selectedAIOBasicSet: (state: IApplicationState) => state.calculationState.selectedAIOBasicSet,

    productDataList: projectApi.makeSelectBase('productData'),
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: ReduxFormKeys.ventilationComponentsForm,
        validate,
        touchOnChange: true,
        touchOnBlur: false,
        keepDirtyOnReinitialize: true,
        enableReinitialize: true,
    })(VentilationComponentsForm)
);


type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

type Props = StateProps & DispatchProps & InjectedFormProps;
