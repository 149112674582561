import * as React from 'react';
import { Field } from 'redux-form';
import { Header, Icon, Label, Table, TableProps } from 'semantic-ui-react';

import { renderCheckbox, renderQuantity } from '../../../../shared/components/FormControls';
import ImageModal from "../../../../shared/components/ImageModal";
import { useLocalization, useProductLanguage } from '../../../../localization/hook';

import { CHARACTERISTIC_KEY, PRODUCT_GROUP } from '../../../product/lookup';
import { IProduct, IProductData, IProductGroup } from "../../../product/types";
import { ProductSelector } from '../../CalculationService/VentilationComponents';

import "./_ProductsTable.scss";

const COLUMNS = ['quantity', 'name', 'dhNo', 'ventilationArea', 'BxH', 'recess', 'recommendation'] as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
type COLUMNS = typeof COLUMNS[number];

export type ProductsTableProps = TableProps & {
    selector?: Pick<ProductSelector, 'id'|'isRecommended'>[];
    productData: IProductData;
    showBracketOptionOnGroups?: boolean;
};
export const ProductsTable: React.FC<ProductsTableProps> = ({
    selector,
    productData,
    className,
    showBracketOptionOnGroups,
    ...tableProps
}) => {
    const { localize } = useLocalization();


    const sortedProducts = React.useMemo(() => {
        const res = [...productData.products].sort((a, b) => {
            if (b.height === null || b.width === null || a.height === null || a.width === null) {
                if (a.dhNo === null) return b.dhNo === null ? a.id.localeCompare(b.id) : +1;
                if (b.dhNo === null) return -1;
                return a.dhNo.localeCompare(b.dhNo);
            } else {
                return (a.width * a.height) - (b.width * b.height);
            }
        });
        return res;
    }, [productData.products]);

    const availableProducts: readonly ProductSelector[] = React.useMemo(() => {
        if (!selector) {
            return sortedProducts;
        }

        return sortedProducts.map(p => {
            const sel = selector.find(s => s.id === p.id);
            if (!sel) {
                return null;
            }

            return {
                ...p,
                isRecommended: sel.isRecommended
            };
        }).filter(p => !!p);
    }, [sortedProducts, selector]);

    const groups = React.useMemo(
        () => productData.productGroups
            .map(g => ({
                ...g,
                products: availableProducts.filter(p => p.productGroupId === g.id),
                characteristics: productData.characteristics
                    .filter(c => c.productGroupId === g.id)
                    .map(c => c.characteristicKey),
            }))
            .filter(g => g.products.length > 0),
        [productData.productGroups, productData.characteristics, availableProducts]
    );


    return (
        <Table {...{ ...tableProps, className: (!!className ? `${className} ` : '') + 'ProductsTable' }}>
            <thead>
                <tr>
                    {COLUMNS.map(key => (
                        <th key={key} className={key}>{localize('ventComponents.table.column.' + key)}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {groups.map(group => <ProductsTableGroupRows key={group.id}
                    columns={COLUMNS}
                    group={group}
                    products={group.products}
                    characteristics={group.characteristics}
                    showBracketOption={showBracketOptionOnGroups}
                />)}
            </tbody>
        </Table>
    );
};

/** Number of products to show per group initially (before pressing "Show all") */
const COLLAPSED_PRODUCT_COUNT_PER_GROUP = 2;

interface ProductsTableGroupRowsProps {
    columns: readonly COLUMNS[];
    group: IProductGroup;
    products: readonly IProduct[];
    characteristics: readonly CHARACTERISTIC_KEY[];
    showBracketOption?: boolean;
}
const ProductsTableGroupRows: React.FC<ProductsTableGroupRowsProps> = ({
    columns,
    group,
    products,
    characteristics,
    showBracketOption,
}) => {
    const { localize, localizeArea } = useLocalization();
    const language = useProductLanguage();

    const [showAll, setShowAll] = React.useState(false);
    const onShowAll = React.useCallback(() => setShowAll(x => !x), [setShowAll]);

    const productsToShow = React.useMemo(() => {
        return showAll ? products : products.slice(0, COLLAPSED_PRODUCT_COUNT_PER_GROUP);
    }, [products, showAll, COLLAPSED_PRODUCT_COUNT_PER_GROUP]);

    return (<React.Fragment key={`product-group-${group.id}`}>
        <tr key={`product-group-heading-${group.id}`} className="row-group">
            <td className="image">
                {group.imageUrl && (
                    <ImageModal title={group.description[language]}
                        showThumbnail={true}
                        thumbnailClassName="thumbnail"
                        height={48} width={48}
                        imageUrl={group.imageUrl}
                    />
                )}
            </td>
            <td colSpan={2}>
                <Header as="h4">{group.description[language]}</Header>
            </td>
            <td colSpan={3} className="info">
                <div>
                {[CHARACTERISTIC_KEY.FLUSH_MOUNTING, CHARACTERISTIC_KEY.SURFACE_MOUNTING].map(mounting => (
                    characteristics.find(c => c === mounting) &&
                    <span key={mounting}>{localize('characteristic.' + mounting)}</span>
                ))}
                {group.planningFileUrl && (
                    <a href={group.planningFileUrl} target="_blank" rel="noopener noreferrer">
                        {localize("link.planningfile")} <Icon name="external" size="small" />
                    </a>
                )}
                </div>
            </td>
            <td colSpan={Math.max(7, columns.length) - 1 - 2 - 3}/>
        </tr>
        {showBracketOption && <BracketOptionRow productGroupId={group.id}/>}
        {productsToShow.map(product => (
            <tr key={`product-group-product-${group.id}-${product.id}`} className="row-group-product">
                <td className="quantity">
                    <Field min={0} max={20} name={`quantity-${product.id}`} component={renderQuantity} />
                </td>
                <td className="description">{product.description[language]}</td>
                <td className="dhNo">{product.dhNo}</td>
                <td className="ventilationArea">
                    {localizeArea(product.ventilationArea)}
                </td>
                <td className="BxH">{product.bxH}</td>
                <td className="recess">{product.recess}</td>
                <td className="recommendation">
                    {product.isRecommended && (
                        <Label className="recRibbon" ribbon="right">{localize('info.recommendedForYou')}</Label>
                    )}
                </td>
            </tr>
        ))}
        {products.length > COLLAPSED_PRODUCT_COUNT_PER_GROUP && (
            <tr key={`product-group-more-${group.id}`} className="row-group-more">
                <td colSpan={7}>
                    <a onClick={onShowAll}>{showAll
                        ? localize("ventComponents.table.showLess")
                        : localize("ventComponents.table.showAll", { count: products.length })
                    }</a>
                </td>
            </tr>
        )}
    </React.Fragment>);
}

const BracketOptionRow: React.FC<{ productGroupId: PRODUCT_GROUP }> = props => {
    const { localize } = useLocalization();

    if (!PRODUCT_GROUPS_WITH_BRACKET.includes(props.productGroupId)) {
        return null;
    }

    return (
        <tr key={`product-group-bracket-${props.productGroupId}`} className="row-group-bracket">
            <td className="quantity">
                <Field component={renderCheckbox} name="includeEinbauwinkel"/>
            </td>
            <td colSpan={6} className="info">
                {localize('field.includeEinbauwinkel')}
            </td>
        </tr>
    );
}

const PRODUCT_GROUPS_WITH_BRACKET = [PRODUCT_GROUP.JK190, PRODUCT_GROUP.JK190DB];
