import * as React from 'react';

import { useLocalization } from '../../localization/hook';
import { useFreshworksOpenWithPrefill } from '../../features/freshworks-widget/useFreshworksWidget.hook';

import './Spinner.scss';


// To support new operations, add an option to this enum by chosing a unique opcode
export enum SpinnerOpCode {
    INDEX_CONFIG    = "BKCC-I9C",
    // Authentication
    AP_AUTH         = "BKCC-AP1",
    AC_PENDING      = "BKCC-AC1",
    // CalculationPage
    CP_STATE        = "BKCC-C9S",
    CP_PRODUCT_DATA = "BKCC-C9P",
    // CostCalculationForm ("calculation page 4")
    C4_SETTINGS     = "BKCC-C4S",
    C4_USER         = "BKCC-C4U",
    C4_FORM_READY   = "BKCC-C4R",
    C4_PROJECT      = "BKCC-C4P",
    C4_COSTS        = "BKCC-C4C",
    C4_FILES        = "BKCC-C4F",
}

export interface SpinnerProps {
    /** Show the spinner loading state */
    loading: boolean;
    /** Show the spinner error state with a message */
    error?: Error|string;
    /** The operation code */
    operation: SpinnerOpCode;
    /** Override the timeout during the loading state when the spinner message will escalate; 0 disables escalation; default 5000 ms */
    escalateTimeoutMs?: number;

    /**
     * Add `ui segment` CSS classes  
     * `small`: Also `small` add CSS class
     */
    segment?: boolean|'small';
}

/**
 * Show a loading spinner during a loading operation,
 * escalate to an error message in a failure or after a timeout.
 * 
 * Renders nothing if in neither loading nor error state.
 * 
 * If Freshdesk Widget is available, show a link that opens and prefills it for an error report to Web-App Support.
 */
export const Spinner: React.FC<SpinnerProps> = props => {
    const isLoading = props.loading;
    const isError = !props.loading && !!props.error;

    const escalateTimeoutMs = props.escalateTimeoutMs ?? 5000;
    const [isEscalateTimeout, setIsEscalateTimeout] = React.useState(false);

    React.useEffect(() => {
        if (escalateTimeoutMs === 0) {
            return;
        }

        if (!isLoading) {
            return;
        }

        const timeout = setTimeout(() => {
            setIsEscalateTimeout(true);
        }, escalateTimeoutMs);

        return () => {
            clearTimeout(timeout);
            setIsEscalateTimeout(false);
        }
    }, [escalateTimeoutMs, setIsEscalateTimeout, props.operation, isLoading]);

    const freshworksOpenWithPrefill = useFreshworksOpenWithPrefill();

    const onReportClick = React.useCallback(() => {
        const description = isError
            ? `Loading Error with Code [${props.operation}]:\n${props.error?.toString()}`
            : `Loading Spinner with Code [${props.operation}]`;
        freshworksOpenWithPrefill?.({
            description,
            custom_fields: {
                'cf_bereich_bluekit': 'Web-App Support',
            },
        });
    }, [freshworksOpenWithPrefill, props.operation, isError, props.error]);

    const { localize } = useLocalization();

    const className = React.useMemo(() => {
        const classNames = ["Spinner"];
        if (props.segment) classNames.push("ui segment");
        if (props.segment === "small") classNames.push("small");
        return classNames.join(" ");
    }, [props.segment]);

    if (!isLoading && !isError) {
        return null;
    }

    return (
        <div className={className}>
            <p></p>
            <div className="ui active inverted dimmer">
                <div className="ui big text loader">
                    {isError ? props.error?.toString() : localize('loading...')}
                    {(isError || isEscalateTimeout) && !!freshworksOpenWithPrefill && <>
                        <br/>
                        {`[${props.operation}]`}
                        <br/>
                        <a onClick={onReportClick}>Report this to Web-Application Support</a>
                    </>}
                </div>
            </div>
        </div>
    );
};
