import {cloneDeep, orderBy} from "lodash-es";


import {CHARACTERISTIC_KEY, PRODUCT_CATEGORY, PRODUCT_GROUP, PRODUCT_TYPE} from '../product/lookup';
import { ICharacteristic, IProduct, IProductGroup } from "../product/types";
import {IFormValue} from "../project/ProjectModels";

import {
    ICalculationFormsState,
    IProjectProduct
} from "./CalculationModels";
import {
    BasicInputsFormModel,
    ConfigurationInputsFormModel, 
    VentilationComponentsFormModel,
    CostCalculationFormModel,
    defaultFormValues
} from "./models/FormModels";
import { ICalculationState } from "./CalculationReducer";


// Product Data Lookups

export function getProductById(productsData: readonly IProduct[], productId: string|null): IProduct|null {
    return productId === null ? null : productsData.find(p => p.id === productId);
}

export function getProductByKey(productsData: readonly IProduct[], key: string): IProduct|null {
    return productsData.find(x => x.productKey === key);
}

// Predicates for Array.prototype.filter

function getIdFromGroupOrId<T extends Pick<IProductGroup, 'id'>>(g: T|PRODUCT_GROUP): PRODUCT_GROUP {
    return typeof g === 'object' && Object.prototype.hasOwnProperty.call(g, 'id')
        ? (g as T).id
        : (g as PRODUCT_GROUP)
}

export function filterProductsByVentilationArea(minArea: number, maxArea: number|null = null, includeNull: boolean = false) {
    return <T extends Pick<IProduct, 'ventilationArea'>>(product: T) =>
        (includeNull && product.ventilationArea === null) || (
            product.ventilationArea >= minArea &&
            (maxArea === null || product.ventilationArea < maxArea)
        )
}

export function filterProductsByTypes(types: PRODUCT_TYPE[], not: boolean = false) {
    return <T extends Pick<IProduct, 'productTypeId'>>(product: T) => not !== types.includes(product.productTypeId);
}

export function filterProductsByGroups(groups: (IProductGroup | PRODUCT_GROUP)[], not: boolean = false) {
    const groupIds = groups.map(getIdFromGroupOrId);

    return <T extends Pick<IProduct, 'productGroupId'>>(product: T) => not !== groupIds.includes(product.productGroupId)
}

export function filterProductsByCategories(categories: readonly PRODUCT_CATEGORY[], productGroupsData: readonly IProductGroup[], not: boolean = false) {
    const groups = productGroupsData.filter(filterProductGroupsByCategories(categories))
    return filterProductsByGroups(groups, not);
}

export function filterProductsByCharacteristicsAny(characteristics: readonly CHARACTERISTIC_KEY[], characteristicsData: readonly ICharacteristic[], not: boolean = false) {
    return filterProductsByGroups(
        characteristicsData.filter(filterCharacteristicsByKeys(characteristics)).map(c => c.productGroupId),
        not
    );
}

export function filterProductsByCharacteristicsAll(characteristics: readonly CHARACTERISTIC_KEY[], characteristicsData: readonly ICharacteristic[], not: boolean = false) {
    const predicates = characteristics.map(c => filterProductsByCharacteristicsAny([c], characteristicsData));
    return <T extends Pick<IProduct, 'productGroupId'>>(p: T) => not !== predicates.every(pred => pred(p))
}

function filterProductGroupsByCategories(categories: readonly PRODUCT_CATEGORY[], not: boolean = false) {
    return <T extends Pick<IProductGroup, 'productCategoryId'>>(g: T) => not !== categories.includes(g.productCategoryId);
}

export function filterProductGroupsByCharacteristicsAll(characteristics: readonly CHARACTERISTIC_KEY[], characteristicsData: readonly ICharacteristic[], not: boolean = false) {
    const groupIds = characteristicsData
        .filter(filterCharacteristicsByKeys(characteristics))
        .map(c => c.productGroupId);

    return <T extends Pick<IProductGroup, 'id'>>(g: T|PRODUCT_GROUP) => not !== groupIds.includes(getIdFromGroupOrId(g));
}

export function filterCharacteristicsByKeys(keys: readonly CHARACTERISTIC_KEY[], not: boolean = false) {
    return (c: ICharacteristic) => not !== keys.includes(c.characteristicKey);
}

// -----
// FormValues
export function getFormValues(formValues: IFormValue[]): ICalculationFormsState {
    const forms: ICalculationFormsState = cloneDeep(defaultFormValues);

    setFormValuesFromArray(new BasicInputsFormModel(), formValues, forms, forms.basicInputsForm.values);
    setFormValuesFromArray(new ConfigurationInputsFormModel(), formValues, forms, forms.configurationInputsForm.values);
    setFormValuesFromArray(new VentilationComponentsFormModel(), formValues, forms, forms.ventilationComponentsForm.values);
    setFormValuesFromArray(new CostCalculationFormModel(), formValues, forms, forms.costCalculationForm.values);

    return forms;
}

function setFormValuesFromArray(formModel: any, formValues: readonly IFormValue[], forms: ICalculationFormsState, targetFormModel: any) {
    Object.keys(formModel).forEach((key) => {
        const formValue = formValues.find(f => f.key === key);
        if (formValue) {
            targetFormModel[key] = formValue.value;
        }
    });
}

export function getFormValuesDictionaryFromForms(form: ICalculationFormsState): IFormValue[] {
    if (!form) {
        return [];
    }

    const formValues = {
        ...form.basicInputsForm?.values,
        ...form.configurationInputsForm?.values,
        ...form.ventilationComponentsForm?.values,
        ...form.costCalculationForm?.values
    };

    let kvps = Object.keys(formValues).map(key => {
        return {
            key,
            value: formValues[key],
        };
    });

    // Filter out undefined values
    kvps = kvps.filter(formValue => formValue.value !== undefined);

    return kvps;
}


// -----
// Recommendation
export function getRecommendedProductId(
    ventComponents: readonly IProduct[],
    productGroupsData: readonly IProductGroup[]
): IProduct['id'] | null {
    const groups = productGroupsData.filter(
        g => !!ventComponents.find(vc => vc.productGroupId === g.id) && g.recommendationOrder2 > 0
    );

    if (groups?.length === 0) return null;

    const recommendedGroupId = orderBy(groups, 'recommendationOrder2', 'asc')[0].id;
    const sortedComponents = orderBy(
        ventComponents.filter(x => x.productGroupId === recommendedGroupId),
        ['width', 'height'],
        ['asc', 'asc']
    );

    return sortedComponents?.length > 0 ? sortedComponents[0].id : null;
}


// -----

export function getProjectProductsFromForms(
    form: Readonly<ICalculationFormsState>,
    loadedProjectId: string,
    calculationState: Readonly<ICalculationState>
): IProjectProduct[] {

    const ventComp = form.ventilationComponentsForm.values;
    const filteredVentilationComponentsKeys = Object.keys(ventComp).filter(x => x.startsWith('quantity-'));

    const ventilationComponents = filteredVentilationComponentsKeys.map((key: string) => (<IProjectProduct>{
        productId: key.replace('quantity-', ''),
        quantity: ventComp[key],
        projectId: loadedProjectId,
        productCategoryId: PRODUCT_CATEGORY.VENTCOMP
    }));

    const nonStandardComponents = calculationState.nonStandardComponents.map(x => (<IProjectProduct>{
        productId: x.productId,
        description: x.description,
        quantity: x.quantity,
        price: x.price,
        projectId: loadedProjectId,
        productCategoryId: PRODUCT_CATEGORY.NONSTANDARDCOMPONENTS
    }));

    return ventilationComponents.concat(nonStandardComponents);
}
